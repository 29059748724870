
#icon {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#icon:hover {
  color: #1890ff;
}

.logo {
  height: 68px;
  width: 560px;
  transition: width 0.2s ease-in-out;
}

.collapse-trigger {
  transition: width 0.2s;
}

.logo.collapsed {
  margin-left: -12px;
  width: 55px;
}

.loggedInUser {
  padding: 16px;
  padding-bottom: 5px;
  margin: auto;
}

#menuTitle {
  padding: 1.5rem;
  color: #ffffff;
}

#headerTitle {
  background: #fff;
  padding: 1.5rem;
  height: 100px;
}

#header {
  padding: 0 24px;
}

#main {
  margin-top: 0.7rem;
}

.ant-layout-sider-trigger {
  border-top-color: #e8e8e8;
  border-top-style: solid;
  border-top-width: thin;
}


#Logout > span {
  padding-right: unset;
}

#Logout {
  margin-right: 2.5rem;
}

.ant-drawer-content {
  overflow-y: scroll;
}

.search {
  padding-top: 15px;
  padding-left: 10px;
}
